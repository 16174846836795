// Solid Colors ---------------
$white: #ffffff;
$black: #000;

// Grey Colors ---------------
$theme-color-2: #ffb013;

// Grey-2Colors ---------------
$theme-color-2-2: #a2a2a2;

// Footer Colors ---------------
$footer-bg: #000;

// basic color
$theme-color: #2467ec;

// basic color
$theme-bg: #f6f8fb;

// Text Colors ---------------
$body-text: #575757;

// Border Colors ---------------
$border: #edeef2;

// Heading Colors ---------------
$heading-color: #141517;

// section-bg Colors ---------------
$section-bg: #f6f8fb;
