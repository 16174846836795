/*----------

27. footer style

----------*/

@use '../abstract' as *;

// footer-style
.footer-area {
  background: $heading-color;
}
.footer-img {
  img {
    margin-bottom: 30px;
  }
}
.footer-widget {
  @media #{sm} {
    margin-bottom: 20px;
  }
  p {
    color: $theme-color-2-2;
    margin-bottom: 30px;
  }
  h3 {
    color: $white;
    margin-bottom: 30px;
    font-size: 20px;
  }
  ul {
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: $theme-color-2-2;
        text-decoration: none;
        &:hover {
          color: $white;
        }
      }
    }
  }
}
.f-w1 {
  margin-right: 0;
}
.f-w2 {
  margin-left: 130px;
  @media #{$xl} {
    margin-left: 75px;
  }
  @media #{$lg} {
    margin-left: 50px;
  }
  @media #{$md,$sm,$xs} {
    margin-left: 0;
  }
}
.f-w3 {
  margin-left: 120px;
  @media #{$xl} {
    margin-left: 70px;
  }
  @media #{$lg} {
    margin-left: 40px;
  }
  @media #{$md,$sm,$xs} {
    margin-left: 0;
  }
}
.f-w4 {
  margin-left: 110px;
  @media #{$xl} {
    margin-left: 70px;
  }
  @media #{$lg} {
    margin-left: 20px;
  }
  @media #{$md,$sm,$xs} {
    margin-left: 0;
  }
}

.footer-icon {
  @media #{$md} {
    margin-bottom: 30px;
  }
  a {
    background: #212730;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 16px;
    color: $white;
    border-radius: 30px;
    text-align: center;
    margin-right: 15px;
    @media #{$md,$lg} {
      margin-right: 9px;
    }
    display: inline-block;
    &:hover {
      background: $theme-color;
    }
  }
}

// footer-style
.copyright-area {
  background: #1a1f27;
  border-radius: 10px 10px 0 0;
  padding: 30px 20px;
  @media #{$sm,$xs} {
    padding: 30px 15px;
  }
}
.copy-right-support {
  display: flex;
  align-items: center;
  top: 5px;
  position: relative;
  @media #{$xs} {
    margin-bottom: 25px;
    margin-top: 10px;
  }
  @media #{$sm} {
    margin-bottom: 20px;
  }
  p {
    color: #a2a2a2;
    margin-bottom: 3px;
    font-size: 14px;
  }
  span {
    color: $theme-color;
  }
}
.copy-right-svg {
  margin-right: 20px;
}
.copyright-svg-content {
  h5 {
    color: $white;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Nunito Sans', sans-serif;
    @media #{$sm} {
      font-size: 16px;
    }
  }
}
.copyright-subcribe {
  position: relative;
  @media #{$md} {
    margin-top: 30px;
  }
}
.copyright-subcribe .field input {
  height: 70px;
  width: 100%;
  padding: 0 190px 0 45px;
  background: $heading-color;
  border: 0;
  border-radius: 5px;
  color: $white;
  outline: none;
  @media #{$lg} {
    padding: 0 150px 0 45px;
  }
  @media #{$md} {
    padding-left: 0px 0px 0px 45px;
  }
  @media #{$xs} {
    padding: 0 20px 0 45px;
    height: 60px;
  }
  &::placeholder {
    color: $theme-color-2-2;
  }
  &::-moz-placeholder {
    color: $theme-color-2-2;
  }
}
.copyright-subcribe button {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 50px;
  width: 160px;
  background: $theme-color;
  border-radius: 5px;
  color: $white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media #{$xs} {
    margin-bottom: 20px;
    position: relative;
    left: 0;
  }
  @media #{$lg} {
    width: 130px;
  }
  &:hover {
    background: $theme-color-2;
    color: $heading-color;
  }
}
.widget__subscribe .field {
  position: relative;
  &::before {
    position: absolute;
    content: '\f0e0';
    font-family: 'Font Awesome 5 Pro';
    color: $theme-color-2-2;
    color: $theme-color-2-2;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }
}
.copyright-text {
  width: 196px;
  position: relative;
  top: 9px;
  @media #{$sm} {
    margin-bottom: 30px;
  }
  p {
    color: $white;
  }
  span {
    color: $theme-color;
  }
}
// border-style
.border-line::after {
  position: absolute;
  content: '';
  height: 70px;
  width: 1px;
  background: #373a3e;
  top: -9px;
  right: -28%;
  @media #{$xl} {
    right: -17%;
  }
  @media #{$sm,$xs,$md,$lg} {
    display: none;
  }
}
.border-line-2::before {
  position: absolute;
  content: '';
  height: 70px;
  width: 1px;
  background: #373a3e;
  right: 50px;
  top: -9px;
  @media #{$xl} {
    right: 12px;
  }
  @media #{$sm,$xs,$md,$lg} {
    display: none;
  }
}
// university-footer
.university-footer-area {
  background: $section-bg;
}

.uf-w1 {
  margin-right: -30px;
  @media #{$md,$sm,$xs} {
    margin-right: 0;
  }
}
.uf-w2 {
  margin-left: 130px;
  @media #{$xl} {
    margin-left: 70px;
  }
  @media #{$lg} {
    margin-left: 50px;
  }
  @media #{$md,$sm,$xs} {
    margin-left: 0;
  }
}
.uf-w3 {
  margin-left: 80px;
  @media #{$xl} {
    margin-left: 40px;
  }
  @media #{$lg} {
    margin-left: 40px;
  }
  @media #{$md,$sm,$xs} {
    margin-left: 0;
  }
}
.uf-w4 {
  margin-left: 26px;
  @media #{$xl} {
    margin-left: -20px;
  }
  @media #{$lg} {
    margin-left: 0px;
  }
  @media #{$md,$sm,$xs} {
    margin-left: 0;
  }
}
.university-footer-widget-body {
  h3 {
    color: $heading-color;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
.university-footer-icon {
  ul {
    li {
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 10px;
      color: $body-text;
      @media #{$lg} {
        margin-right: 9px;
      }
    }
  }
  a {
    color: #a8adb7;
    background: $white;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 20px;
    text-align: center;
    display: inline-block;
    &:hover {
      background: $theme-color;
      color: $white;
    }
  }
}
.university-footer-link {
  ul {
    li {
      margin-bottom: 10px;
      a {
        &:hover {
          color: $heading-color;
        }
      }
    }
  }
}
.university-footer-subscribe {
  input {
    width: 100%;
    height: 50px;
    background: $white;
    outline: none;
    border: 0;
    padding: 0 60px 0 40px;
    color: #a5a5a5;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    background: $theme-color-2;
    height: 100%;
    width: 50px;
    color: $white;
  }
}
.university-footer-subscribe .field::before {
  position: absolute;
  content: '\f0e0';
  font-family: 'Font Awesome 5 Pro';
  color: #a2a2a2;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}
.app-store {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.footer-copyright-right {
  ul {
    li {
      li {
        display: inline-block;
        margin-left: 40px;
        color: #a2a2a2;
      }
    }
  }
}
.footer-bottom {
  background: $heading-color;
}

.footer-copyright-left {
  padding-left: 55px;
  p {
    color: #a2a2a2;
    span {
      color: $theme-color;
      margin-left: 10px;
      font-size: 18px;
    }
  }
}

// footer-submenu
.university-sub-footer {
  background: $heading-color;
  padding: 25px 0;
  line-height: 1;
}
.sub-footer-link {
  ul {
    display: flex;
    justify-content: flex-end;
    @media #{$xs,$sm} {
      margin-top: 15px;
      justify-content: flex-start;
    }
    li {
      margin-left: 40px;
      &:first-child {
        margin-left: 0;
      }
      a {
        color: #a2a2a2;
        position: relative;
        &::before {
          position: absolute;
          background: $body-text;
          width: 1px;
          height: 15px;
          content: '';
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
        }
        &:hover {
          color: $white;
        }
      }
      &:first-child a::before {
        display: none;
      }
    }
  }
}
.sub-footer-text {
  span {
    color: #a2a2a2;
    @media #{$sm,$xs} {
      margin-bottom: 10px;
      display: inline-block;
    }
    a {
      color: $theme-color;
    }
  }
}
